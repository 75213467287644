import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/eczema/eucerin-eczema-bodywash-front.webp";
import backImage from "images/body/eczema/eucerin-eczema-bodywash-back.webp";
import frontZoomImage from "images/body/eczema/eucerin-eczema-bodywash-front-zoom.webp";
import backZoomImage from "images/body/eczema/eucerin-eczema-bodywash-back-zoom.webp";

import img4 from "images/callouts/cout-footer-aquaphor.webp";
import img5 from "images/callouts/cout-footer-eczema-young.webp";
import img6 from "images/callouts/cout-eczema-card.webp";

import imgChart1 from "images/body/eczema/chart-eucerin-eczema-skin-tolerability.webp";
import imgChart2 from "images/body/eczema/chart-eucerin-eczema-itching.webp";
import imgChart3 from "images/body/eczema/chart-eucerin-eczema-dry-irritation.webp";

import "./eucerineczemabodywash.scss";

function IndexPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-eczema page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Eczema Relief Cream & Body Wash"
        pageDescription="Learn about Eucerin Eczema Relief Cream & Body Wash"
      />
      <div className="gradient-bg gray-grad">
        <div className="inner-body-content gradient-bg">
          <div className="top-content-level-3">
            <section className="inner-centered-container">
              <ProductDetails
                categoryBgColor="#A70531"
                categoryName="ECZEMA"
                categoryFontColor="#ffffff"
                productName="Eucerin<sup>®</sup> Eczema Relief Cream & Body
                Wash"
                productSlider={[frontImage, backImage]}
                zoomImages={[frontZoomImage, backZoomImage]}
              >
                <div className="functions">
                  <p className="font-semi-bold red-text sub-title">
                    Calms while it washes
                  </p>
                  <p>
                    <span className="font-semi-bold">
                      Eucerin Eczema Relief Cream &amp; Body Wash
                    </span>{" "}
                    is a gentle, non-foaming, soap-free body wash optimized to
                    the pH of skin and designed to relieve and reduce itch.
                  </p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Lower pH than the leading therapeutic body washes: ~4.5
                      </span>
                    </li>
                    <li>
                      <span className="font-semi-bold">
                        Demonstrated proven relief in as little as 1 week
                        <sup>1</sup>
                      </span>
                    </li>
                    <li>
                      <span>
                        Formulated with colloidal oatmeal (skin protectant) to
                        provide a protective layer and soothe skin
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients">
                  <p className="font-semi-bold red-text sub-title">
                    Active ingredient
                  </p>
                  <p className="font-semi-bold">
                    2% colloidal oatmeal (skin protectant)
                  </p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Provides a protective layer to soothe; reduces itch
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients no-brd">
                  <p className="font-semi-bold red-text sub-title">
                    Key ingredients
                  </p>
                  <p className="font-semi-bold">Ceramide NP</p>
                  <ul className="red-bullets">
                    <li>
                      <span>Helps support skin’s moisture barrier</span>
                    </li>
                  </ul>
                  <p className="font-semi-bold">Gentle surfactant system</p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Gentle, non-foaming, low-surfactant formula minimizes
                        dryness and irritation from bathing
                      </span>
                    </li>
                  </ul>
                </div>
              </ProductDetails>
              <Row>
                <Col xs={12}>
                  <div className="features">
                    <p className="font-semi-bold red-text sub-title">
                      Formulated for eczema-prone skin
                    </p>
                    <ul className="checkmarks red">
                      <li>
                        <span>Fragrance-free </span>
                      </li>
                      <li>
                        <span>dye-free </span>
                      </li>
                      <li>
                        <span>paraben-free</span>
                      </li>
                      <li>
                        <span>non-comedogenic</span>
                      </li>
                    </ul>
                  </div>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
              </Row>
            </section>
          </div>
          <div className="gradient-bg">
            <section className="inner-centered-container">
              <AccordionContent
                accordionHeading="SKIN TOLERABILITY"
                productThemeColor="eczema-red"
                id="tolerability"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart1}
                      className="accordion-chart"
                      alt="Skin Tolerability improvement chart by week 1"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients (N=35) bathed with
                      wash once daily.
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>
                      &lt;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      <p className="spacer">46% improvement in erythema</p>
                      <p className="spacer">44% improvement in dryness</p>
                      <p className="spacer">55% improvement in itching</p>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="ITCHING"
                productThemeColor="eczema-red"
                id="itching"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart2}
                      className="accordion-chart"
                      alt="Chart with decrease in itching after bathing"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients (N=35) bathed with
                      wash once daily.
                    </p>
                    <p className="references-symbols last">
                      *Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>&le;0.05).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      Statistically significant reduction in patients reporting
                      itching after bathing
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="DRYNESS & IRRITATION"
                productThemeColor="eczema-red"
                id="drynessirritation"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart3}
                      className="accordion-chart"
                      alt="Chart with decrease in dryness and irritation after bathing"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients (N=35) bathed with
                      wash once daily.
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>
                      &le;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      <p className="spacer">
                        2x less dryness and irritation after bathing at Week 1
                      </p>
                      <p className="spacer">
                        4x less dryness and irritation after bathing at Week 2
                      </p>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>
            </section>
          </div>
          <section className="callouts-container gradient-bg section-support-resource red">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                  <TypeC
                    buttonText="Download Here"
                    buttonUrl={getUrlFromSlug(
                      "eucerin-eczema-relief-regimen",
                      state?.sanityData?.allResources
                    )}
                    className="center-v"
                    isExternalLink={true}
                    targetOpen="_blank"
                  >
                    <p className="reg-text-big">
                      Support your recommendation with this resource
                    </p>
                    <p className="reg-text">
                      Review with patients how the ingredients help restore the
                      natural pH of skin.
                    </p>
                  </TypeC>
                </Col>
                <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                  <img
                    src={img6}
                    alt="Protect Eczema Prone skin information"
                    className="cout-product-img"
                  />
                </Col>
              </Row>
            </div>
          </section>

          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img4}
                    imgAlt="DRY, DAMAGED SKIN"
                    caption="Dry, damaged skin from eczema? Take the Aquaphor<sup>®</sup> Approach to healing"
                    buttonText="DRY, DAMAGED SKIN"
                    buttonUrl="/body/damagedskin"
                    imgStyles={{ height: 158 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img5}
                    imgAlt="Explore Baby Care"
                    caption="Eczema relief for your young<br class='show-desktop' /> patients"
                    buttonText="Explore Baby Care"
                    buttonUrl="/baby/eczema"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
